 $blue: #0d99d6;
 $dark: #094a66;
 $orange: #F18F01;
 $cyan: #0ddaad;
  
 $primary: $blue;
 $secondary: $orange;
 
 @import "~bootstrap/scss/functions";
 @import "~bootstrap/scss/variables";

.dark {
  $min-contrast-ratio: 1.8;
  $border-radius: 0.5rem;
  $border-radius-lg: 1rem;
  $modal-header-border-width: 0;
  
  $body-bg: $gray-900;
  $body-color: $gray-100;
  $surface: $dark;
  $on-surface: $gray-100;
  $surface-variant: $surface;
  $on-surface-variant: $gray-100;
  $on-background: $gray-100;

  $custom-theme-colors: (
    "surface": $surface,
    "surface-variant": $surface-variant,
    "on-surface": $on-surface,
    "on-surface-variant": $on-surface-variant,
    "on-background": $on-background,
  );

  $custom-utilities-colors: $custom-theme-colors;
  $custom-table-colors: $custom-theme-colors;
  $theme-colors: map-merge($theme-colors, $custom-theme-colors);
  $utilities-text-colors: map-merge($utilities-text-colors, $custom-utilities-colors );
  $utilities-bg-colors: map-merge($utilities-bg-colors, $custom-utilities-colors);
  $table-variants: map-merge($table-variants, $custom-table-colors);

  @import "~bootstrap/scss/bootstrap.scss";

  .simple-keyboard .hg-button {
    background: $primary;
    color: $white;
  }
}