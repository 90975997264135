@import "~bootstrap-icons/font/bootstrap-icons.css";

html, body, #root {
  height: 100%;
  overscroll-behavior: none;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.nocursor * {cursor:none!important}

$blue: #0d99d6;
$dark: #004C3F;
$orange: #ffcb67;
$cyan: #0ddaad;
$primary: $dark;
$secondary: $orange;
$info: $orange;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$body-bg: #FFF8F8;
$input-bg: #FFF;

$min-contrast-ratio: 1.8;
$spinner-border-width: 0.10em;

$surface: $white;
$on-surface: $gray-900;
$surface-variant: $primary;
$on-surface-variant: $gray-100;
$on-background: $gray-900;

// dark
// $body-bg: $gray-900;
// $body-color: $gray-100;
// $surface: $dark;
// $on-surface: $gray-100;
// $surface-variant: $surface;
// $on-background: $gray-100;

$custom-theme-colors: (
  "surface": $surface,
  "surface-variant": $surface-variant,
  "on-surface": $on-surface,
  "on-surface-variant": $on-surface-variant,
  "on-background": $on-background,
);

$custom-utilities-colors: $custom-theme-colors;
$custom-table-colors: $custom-theme-colors;
$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$utilities-text-colors: map-merge($utilities-text-colors, $custom-utilities-colors );
$utilities-bg-colors: map-merge($utilities-bg-colors, $custom-utilities-colors);
$table-variants: map-merge($table-variants, $custom-table-colors);

@import "~bootstrap/scss/bootstrap.scss";

.form-control:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.simple-keyboard .hg-button {
  height: 45px
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}
